@charset 'UTF-8';
@import "/Users/huongalive/Website/vhost/dlofre/src/scss/utility/_media-queries.scss";
@import "/Users/huongalive/Website/vhost/dlofre/src/scss/utility/_mixin.scss";


.spots{
  .pageTitle{
    @include PC{
      padding: 78px 0 40px;
    }
    @include SP{
      padding: 26px 0 34px;
    }
  }
  .mainVisual{
    text-align: center;
    @include SP{
      margin: 0 -7px;
    }
  }
  .anchor{
    @include clearfix();
    max-width: 820px;
    width: 100%;
    margin: 92px auto 53px;
    @include SP{
      margin: 43px auto 30px;
    }
    li{
      width: 21%;
      float: left;
      @include PC{
        margin: 0 2% 32px;
      }
      @include SP{
        width: 31.5%;
        margin-right: 2.5%;
        &:nth-child(3n+3){
          margin-right: 0;
        }
      }
      a{
        display: block;
        border-bottom: 1px solid #c4c4c4;
        color: #101010;
        padding-bottom: 11px;
        @include font(13,22px,0.08em, 300);
        background: url(../../img/common/icon/ico_arrow_dark.svg) no-repeat;
        background-position: top 6px right 2px;
        @include SP{
          @include font-size(9);
          padding-bottom: 5px;
          margin: 0 0 9px;
          background-size: 7px 7px;
          background-position: top 6px right 3px;
        }
        @include IP5{
          letter-spacing: 0;
          background-position: top 7px right 3px;
          @include font-size(8);
        }
      }
    }
  }
  .content{
    @include PC{
      margin-bottom: 175px;
    }
    @include SP{
      margin-bottom: 39px;
    }
    &__heading{
      color: #1c1c1c;
      @include noto-serif();
      @include font(32,"",0.1em, normal);
      margin: 0 0 33px;
      @include SP{
        @include font-size(20);
        margin-bottom: 20px;
      }
    }
    &__item{
      @include PC{
        margin-bottom: 27px;
      }
      @include SP{
        margin-bottom: 22px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__list{
      @include flexbox();
      @include justify-content(flex-start);
      @include flex-wrap(wrap);
      width: 100%;
    }
    &__title{
      text-align: left;
      color: #1c1c1c;
      display: flex;
      align-items: flex-start;
      @include SP{
        font-size: 12px;
      }
      .number{
        width: 28px;
        height: 28px;
        background: #1c1c1c;
        font-size: 19px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        display: flex;
        @include noto-serif();
        @include align-items(center);
        @include justify-content(center);
        @include SP{
          width: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 32px;
        }
      }
      .text{
        @include PC{
          @include font(16,22px,0.06em, normal);
          width: calc(100% - 28px);
          padding: 4px 0 0 12px;
        }
        @include SP{
          width: calc(100% - 20px);
          padding: 2px 0 0 9px;
          line-height: 20px;
        }
      }
    }
    &__child{
      width: 100%;
      margin: 0 0 64px;
      position: relative;
      @include PC{
        // max-width: 348px;
        max-width: 31.637%;
        margin: 0 2.4% 62px 0;
        &:nth-child(3n+3){
          margin-right: 0;
        }
      }
      @include ipad{
        max-width: 31.73%;
      }
      @include SP{
        max-width: 49%;
        margin-bottom: 26px;
        margin-right: 2%;
        &:nth-child(2n+2){
          margin-right: 0;
        }
      }
      @include IP5{
        max-width: 48%;
      }
      a{
        display: block;
      }
    }
    .img_thumb{
      @include aspect-ratio(348px, 232px);
      @include PC{
        margin: 0 0 21px;
      }
      @include SP{
        margin-bottom: 8px;
      }
    }
  }
}
// ------------------------------------------------------------------------
.cafe-spots{
  .container{
    position: relative;
  }
  // -----------------------------------------------------------------------
  .pageTitle{
    @include PC{
      padding: 65px 0 40px;
      &__en{
        margin: 0 0 20px;
      }
      &__jp{
        line-height: 20px;
      }
    }
    @include SP{
      padding: 34px 0 31px;
      &__inner{
        display: block;
      }
      &__en{
        text-align: center;
        line-height: 24px;
        margin: 0 auto 10px;
      }
      &__jp{
        font-size: 12px;
        line-height: inherit;
        text-align: center;
        letter-spacing: 0.3em;
        line-height: 24px;
        margin: 0 auto;
      }
    }
  }
  // -----------------------------------------------------------------------
  .banner{
    @include PC{
      max-width: 175px;
      width: 100%;
      margin: 0 0 0 auto;
      position: absolute;
      right: 20px;
      top: 78px;
      span, a{
        margin-bottom: 8px;
        display: block;
        img{
          width: 100%;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    @include SP {
      display: flex;
      justify-content: space-between;
      a, span{
        max-width: calc(50% - 5px);
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
  }
  // -----------------------------------------------------------------------
  .tagwrap{
    @include SP {
      background: #edf2f4;
    }
  }
  .tag{
    @include PC{
      @include flexbox();
      @include justify-content(center);
      @include flex-wrap(wrap);
      margin: 29px auto 51px;
      max-width: 1115px;
      width: 100%;
      padding: 0 7px;
    }
    @include SP {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      transition: all 0.2s;
      transform: scale(0.98);
      will-change: transform;
      user-select: none;
      cursor: pointer;
      margin: 17px auto 0;
      height: 71px;
      padding: 17px 0 49px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    li{
      @include PC{
        margin: 0 9px 10px 0;
        &:last-child{
          margin: 0;
        }
      }
      @include SP {
        display: inline-block;
        margin: 0 7px 0 0;
        &:first-child{
          margin-left: 4px;
        }
      }
    }
    a, span{
      border-radius: 5px;
      background: #fff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      text-align: left;
      color: #101010;
      @include font(12,22px,"", 300);
      padding: 8px 15px;
      @include PC{
        display: block;
      }
      @include SP{
        padding: 8px 10px;
        min-width: 52px;
        display: block;
        text-align: center;
      }
    }
  }
  // -----------------------------------------------------------------------
  .owl-carousel{
    height: 100%;
    width: 100%;
    margin: 0 auto;
    .owl-item{
      position: relative;
      .item{
        overflow: hidden;
        position: relative;
      }
    }
    .owl-prev{
      position: absolute;
      left: -5px;
      transform: translateY(-50%);
      z-index: 9;
      background: url(../../img/common/icon/ico_slide_prev.svg) no-repeat !important;
      background-position: center center !important;
      background-size: cover;
      font-size: 0 !important;
      top: calc(50% - 23px);
      @include HOVER {
        &:hover{
          opacity: 0.5;
        }
      }
      @include PC{
        width: 40px;
        height: 40px;
      }
      @include SP{
        width: 30px;
        height: 30px;
        border-radius: 50% !important;
        top: calc(50% - 15px);
      }
    }
    .owl-next{
      position: absolute;
      right: -5px;
      transform: translateY(-50%);
      z-index: 9;
      background: url(../../img/common/icon/ico_slide_next.svg) no-repeat !important;
      background-position: center center !important;
      background-size: cover;
      font-size: 0 !important;
      top: calc(50% - 23px);
      @include HOVER {
        &:hover{
          opacity: 0.5;
        }
      }
      @include PC{
        width: 40px;
        height: 40px;
      }
      @include SP {
        width: 30px;
        height: 30px;
        border-radius: 50% !important;
        top: calc(50% - 15px);
      }
    }
    .owl-dots{
      @include PC{
        margin: 21px 0 48px;
      }
      @include SP{
        margin: 6px 0 14px;
      }
      button{
        span{
          width: 6px;
          height: 6px;
          margin: 5px 5px;
          background: #d5d5d5;
        }
        &.active{
         span{
          background: #000;
         }
        }
      }
    }
    .owl-nav {
      margin-top: 0;
      .disabled{
        opacity: 0;
      }
    }
  }
  // -----------------------------------------------------------------------
  .content{
    max-width: 800px;
    width: 100%;
    @include PC{
      margin: 47px auto 116px;
    }
    @include SP{
      margin: 17px 0 47px;
    }
    h2{
      color: #1c1c1c;
      @include noto-serif();
      @include font(26,50px,0.1em, normal);     
      @include PC{
        margin: 0 0 37px; 
      }
      @include SP{
        font-size: 18px;
        letter-spacing: 0.14em;
        line-height: 30px;
        margin-bottom: 12px;
      }
    }
    p{
      color: #101010;
      @include font(14,24px,0.08em, 300);      
      margin: 0 0 24px;
      &:last-child{
        margin-bottom: 0;
      }
      @include SP{
        font-size: 12px;
      }
    }
  }
  // -----------------------------------------------------------------------
  .newsTtl{
    height: 42px;
    background: #1c1c1c;
    color: #fff;
    padding: 5px 16px;
    @include ffYM();
    @include font(20,"",0.1em, 500);
    margin-bottom: 63px;
    @include SP{
      height: 36px;
      font-size: 18px;
      padding: 3px 20px;
      margin-bottom: 15px;
    }
    & + .other{
      @include SP {
        margin-top: 15px;
      }
    }
  }
  // -----------------------------------------------------------------------
  .btn-link {
    @include PC{
      margin-bottom: 119px;
      margin-top: 22px;
    }
    @include SP{
      margin-bottom: 56px;
    }
  } 
  .ttlHead--mb1{
    .subheading{
      @include PC{
        margin-bottom: 7px;
      }
      @include SP{
        margin-bottom: 0;
      }
    }
  }
  .other--type1 {
    .img_thumb {
      @include PC{
        margin: 0 0 19px;
      }
      @include SP{
        margin: 0 0 6px;
      }
    }
    @include SP{
      .other__item{
        max-width: 49%;
        margin-bottom: 34px;
      }
    }
  }
  .other.margin2 {
    @include PC{
      margin-bottom: 46px;
    }
    @include SP{
      margin-bottom: 25px;
    }
  }
  // -----------------------------------------------------------------------
  .information{
    max-width: 800px;
    width: 100%;
    background: transparent;
    margin: 0 auto 130px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    @include PC{
      padding: 50px 50px;
    }
    @include SP{
      padding: 24px 26px 36px 30px;
      margin-bottom: 60px;
    }
    &__heading{
      color: #101010;
      @include font(22,28px,0.1em, 500);
      @include eb-garamond();
      margin: 0 0 14px 2px;
      @include SP{
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    &__ttl{
      color: #1c1c1c;
      @include noto-serif();
      @include font(16,27px,0.14em, normal);
      margin: 0 0 0 2px;
      @include SP{
        margin-bottom: 8px;
      }
    }
    &__txt{
      color: #101010;
      @include font(13,22px,0.04em, 300);
      @include PC{
        margin: 0 0 39px;
      }
      @include SP{
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.04em;
        line-height: 20px;
        margin: 8px 0 20px;
      }
    }
    &__tbl{
      display: block;
      margin: 7px 0 16px;
      @include SP{
        display: table-cell;
      }
      dt{
        min-width: 45px;
        color: #4e7a91;
        @include font(13,22px,0.08em, 500);
        float: left;
        @include SP{
          font-size: 12px;
          letter-spacing: 0.04em;
          line-height: 20px;
          display: table-cell;
          padding: 1px 0;
        }
      }
      dd{
        color: #101010;
        @include font(13,20px,0.04em, 300);
        margin: 0 0 6px;
        &:last-child{
          margin: 0;
        }
        @include SP{
          display: table-cell;
          padding: 1px 0;
          font-size: 12px;
        }
      }
    }
    &__link{
      text-decoration: underline;
      text-decoration-color: #696969;
      color: #696969;
      @include font(12,20px,0.04em, 300);
      background: url(../../img/common/icon/ico-arrow.svg) no-repeat center left;
      background-size: 8px 8px;
      padding: 0 12px 2px 12px;
      margin: 10px 0 0 0;
      display: block;
      @include SP{
        padding: 0 12px 2px 10px;
        margin: 6px 0 0 0;
        background-position: center left -3px;
      }
    }
    &__calendar{
      @include PC{
        margin: 38px 0 21px;
      }
      @include SP{
        margin: 29px 0 10px;
      }
    }
    &__note{
      margin-bottom: 80px;
      color: #696969;
      @include font(13,20px,0.04em, 300);
      margin: 0 0 42px 4px;
      @include SP{
        font-size: 10px;
        margin: 0 0 18px 1px;
      }
      span{
        &:after{
          content: "・";
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
      }
    }
    &__mark{
      color: #101010;
      @include font(13,20px,0.04em, 300);
      margin-bottom: 20px;
      @include PC{
        margin-left: 2px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      @include SP{
        font-size: 12px;
      }
    }
  }
}


#calendar {
  @include noto-serif;
  #sc_calendar_title {
    @include font-size(18);
    color: #4E7A91;
    margin: auto;
    letter-spacing: 0.08em;
    @include PC {
      width: calc(100% - 85px);
    }
    span{
      @include eb-garamond;
      font-weight: 500;
    }
    @include SP {
      @include font-size(12);
    }
  }
  #sc_event_nav_wrap{
    position: relative;
    margin: auto;
    @include PC {
      width: calc(100% - 85px);
    }
    input[name='sc_prev'], input[name='sc_next']{
      background: #1C1C1C;
      @include border-radius(50%);
      border: none;
      -webkit-appearance: none;
      width: 26px;
      height: 26px;
      position: absolute;
      @include font-size(0);
      top: -28px;
      cursor: pointer;
      z-index: 99;
      @include SP {
        top: -20px;
        width: 20px;
        height: 20px;
      }
    }
    input[name='sc_prev'] {
      right: 38px;
      @include SP {
         right: 30px;
      }
    }
    input[name='sc_next'] {
      right: 0;
    }
    .iconnext, .iconprev {
      top: -19px;
      @include absolute(8px,8px,'','','','');
      display: inline-block;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      pointer-events: none;
      z-index: 999;
      @include SP {
        width: 6px;
        height: 6px;
        top: -13px;
      }
    }
    .iconnext{
      @include transform_c(rotate(-45deg));
      right: 10px;
      @include SP {
        right: 8px;
      }
    }
    .iconprev {
      @include transform_c(rotate(-225deg));
      right: 45px;
      @include SP {
        right: 36px;
      }
    }
  }
  .calendar {
    margin-top: 20px;
    @include SP {
      margin-top: 10px;
    }
    .calendar-row{
      td, th {
        text-align: center;
        vertical-align: middle;
      }
      th{
        @include font-size(13);
        background: #eef2f4;
        height: 35px;
        @include SP {
          height: 26px;
          @include font-size(10);
        }
      }
      td{
        @include font-size(14);
        border-bottom: 1px solid #c4c4c4;
        height: 40px;
        @include SP {
          height: 30px;
           @include font-size(12);
        }
        &.today{
          color: #4E7A91;
        }
      }
    }
  }
}  